import React from 'react';
import '../assets/scss/project-navigation-footer.scss';
import { Link } from 'react-router-dom'; // Make sure you've installed react-router-dom

const ProjectNavigationFooter = ({ nextProject, prevProject }) => {
  return (
    <footer className='project-navigation-footer-container'>
      <Link to={`/project/${prevProject.slug}`} className='prev-project'>
        <span>Prev</span>
        <img className='prev-project-picture' src={`${process.env.PUBLIC_URL}${prevProject.mainImage}`} alt="" />
        {/* Optional: Uncomment to display the name of the previous project */}
        <span>{prevProject.name}</span>
      </Link>
      <Link to={`/project/${nextProject.slug}`} className='next-project'>
        <span>Next</span>
        <img className='next-project-picture' src={`${process.env.PUBLIC_URL}${nextProject.mainImage}`} alt="" />
        {/* Optional: Uncomment to display the name of the next project */}
        <span>{nextProject.name}</span>
      </Link>
    </footer>
  );
};

export default ProjectNavigationFooter;
