import React, { useEffect, useRef, useState } from 'react';
import '../assets/scss/lazy-load-video.scss';
import { useLocation } from 'react-router-dom';

/* 
    In this component:


    - src is the source of the video you want to lazy load.
    - poster is an optional prop for a placeholder image displayed before the video loads.
    - ...props allows you to pass any other props you might need on the <video> tag, such as className for styling.
    - The IntersectionObserver is used to detect when the video element comes into the viewport. 
    It sets isIntersecting to true when the video is about to come into view.
    - The <source> element inside the <video> tag is conditionally rendered based on isIntersecting. 
    
    This means the video will only start loading when the condition is true, achieving lazy loading behavior.
    You might need to adjust the rootMargin value based on your needs, which specifies the margin around the viewport 
    to start loading the video. 
    This component provides a basic lazy loading functionality but can be extended with more features 
    like support for multiple video formats or error handling.
*/

const LazyLoadVideo = ({ src, poster, ...props }) => {
  const videoRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const location = useLocation();
  const containerRef = useRef(null);

  useEffect(() => {
    // Trigger re-animation by resetting the animation
    if (containerRef.current) {
      containerRef.current.style.animation = 'none';
      setTimeout(() => {
        containerRef.current.style.animation = '';
      }, 10); // Short timeout to reset the animation
    }
  }, [location]); // Dependency array includes location to react to route changes


  useEffect(() => {
    const currentRef = videoRef.current;
    if (!currentRef) return;
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isEntryIntersecting = entry.isIntersecting;
        setIsIntersecting(isEntryIntersecting);
  
        if (isEntryIntersecting) {
          currentRef.play().catch(error => console.log("Error trying to play the video:", error));
        }
      },
      { rootMargin: '100px' }
    );
  
    observer.observe(currentRef);
  
    return () => observer.unobserve(currentRef);
  }, [src]); // Now reacts to changes in `src`
  
  return (
    <div className='LazyLoadVideo--container' ref={containerRef} style={props.containerstyle}>
      <h2>{props.videotitle.toUpperCase()}</h2>
      <video
        ref={videoRef}
        // controls // Removed controls: This hides the default browser video controls.
        preload="metadata"
        width={props.width}
        height="auto"
        // poster={poster} // Optional: placeholder image until the video loads
        muted // Most browsers require the video to be muted to autoplay
        loop // Causes the video to loop
        {...props}
      >
        {isIntersecting && <source src={src} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default LazyLoadVideo;
