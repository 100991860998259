import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../assets/data/projects.json';
import '../assets/scss/project-details.scss';
import LazyLoadVideo from '../components/LazyLoadVideo';
import TechnicalSheet from '../components/TechnicalSheet';
import LearningExperiences from '../components/LearningExperiences';
import ProjectInfo from '../components/ProjectInfo';
import ProjectNavigationFooter from '../components/ProjectNavigationFooter';
import ScrollIndicator from '../components/ScrollIndicator';
import LazyLoadImage from '../components/LazyLoadImage';

const ProjectDetails = ({ children, ...rest }) => {
  const { slug } = useParams();
  const project = projectsData.find((p) => p.slug === slug);

  const currentIndex = projectsData.findIndex(p => p.slug === slug);
  const nextProject = projectsData[(currentIndex + 1) % projectsData.length];
  const prevProject = projectsData[(currentIndex - 1 + projectsData.length) % projectsData.length];

  // console.log("prevProject =", prevProject)
  // console.log("nextProject =", nextProject)


  useEffect(() => {
    // Scroll to the top of the page on component update
    window.scrollTo(0, 0);
  }, [slug]);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <main className="projectDetails--container fade" key={slug}>
      <ScrollIndicator></ScrollIndicator>
      <header>
        <h1>{project.name.toUpperCase()}</h1>
        <div className='project-main-picture'>
          <img src={process.env.PUBLIC_URL + project.mainImage} alt="" />
        </div>
      </header>

      <section className='section-about-the-project'>
        <div className='about-project-title'>
          <h2>ABOUT THE PROJECT</h2>
        </div>
        <div className='project-description'>
          <div className='project-about-description'>
            <p className='project-about-content'>{project.aboutTheProject}</p>
          </div>
          <ProjectInfo projectDescription={project.projectDescription} />
        </div>
      </section>

      <section>
        <TechnicalSheet technicalSheet={project.technicalSheet} />
      </section>

      <section>
        {project.imagesOverview.map((image, index) => {
          const imageWidth = '95%';
          // const imagPosition = ;

          return (
            <LazyLoadImage
              key={index+'22'}
              src={image.path}
              alt="Description of image 1"
              width={imageWidth}
              height="auto"
              containerstyle={{ margin: '10px' }}
              imagetitle={image.title}
            />
          );
        })}
      </section>

      <section>
        {project.videosOverview.map((video, index) => {
          // Determine the width based on video.type (desktop or mobile)
          const videoWidth = video.type === 'desktop' ? '75%' : '60%';
          const videoPosition = video.type === 'desktop' ? { justifyContent: 'space-between' } : { justifyContent: 'space-evenly' };

          return (
            <LazyLoadVideo 
              key={index}
              videotype={video.type} // Make sure to pass this prop to use in condition inside LazyLoadVideo
              videotitle={video.title}
              src={video.path}
              poster="/assets/img/projects/lnacarla/profile-lnacarla_red.jpg"
              width={videoWidth} // Passing calculated width
              height="auto"
              containerstyle={videoPosition}
            />
          );
        })}
      </section>

      <LearningExperiences whatdidIlearn={project.whatdidIlearn} />
      <ProjectNavigationFooter prevProject={prevProject} nextProject={nextProject} />
    </main>
  );
};

export default ProjectDetails;
