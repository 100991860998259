import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../assets/scss/navigation.scss';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function extractSlugFromPath(pathname) {
  const pathElements = pathname.split('/');
  return pathElements.length > 1 ? pathElements[pathElements.length - 1] : 'Home';
}

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const location = useLocation();


  useEffect(() => {
    // Close the menu if it's open
    setIsMenuOpen(false);

    // Access the pathname from the location object and set the document title
    const pageTitle = (capitalizeFirstLetter(extractSlugFromPath(location.pathname)) || 'Home').replace('-', ' ')
    document.title = `Portfolio | ${pageTitle !== 'Home' ? `${pageTitle}` : 'Home'}`;
  }, [location.pathname]);

  return (
    <div className='navigation-container'>
      <div className="burger-menu" onClick={toggleMenu}>
        {isMenuOpen ? '✕' : '☰'}
      </div>
      <div className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
        <nav>
          <ul>
            <li>
              <NavLink to="/" className={location.pathname === '/' ? 'active' : 'menu-link'}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" className={location.pathname === '/about' ? 'active' : 'menu-link'}>
                About
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
