// import logo from './logo.svg';
import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from './views/Home';
import About from './views/About';
import ProjectDetails from './views/ProjectDetails';
import Navigation from './components/Navigation';

function App() {
  return (
    <div className="App">
    {/* This div acts as the root container for the app with a class of "App" */}

    <Router>
      {/* Router component from react-router-dom to handle routing in the app */}

      <Navigation />
      {/* Custom Navigation component, likely for site navigation links */}

      <AnimatePresence mode="wait">
        {/* AnimatePresence from Framer Motion library to handle animations for route transitions. 
            The "mode" prop set to "wait" ensures animations complete before new components enter. */}

        <Routes>
          {/* Routes component from react-router-dom to define different routes */}

          <Route exact path="/" element={<Home />} />
          {/* Defines a route for the root URL ("/"). 
              The "exact" prop ensures that this route is only matched for the exact path. 
              The "element" prop renders the Home component for this route. */}

          <Route path="/about" element={<About/>} />
          {/* Defines a route for "/about". 
              The "element" prop renders the About component for this route. */}

          <Route path="/project/:slug" element={<ProjectDetails />} />
          {/* Defines a dynamic route for individual projects. 
              ":slug" is a URL parameter that will be used to render specific project details.
              The "element" prop renders the ProjectDetails component for this route. */}

        </Routes>
      </AnimatePresence>
    </Router>
  </div>
  );
}

export default App;