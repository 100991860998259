import React from 'react';
import '../assets/scss/project-infos.scss';

const ProjectInfo = ({ projectDescription }) => {
  return (
    <div className='project-infos-container'>
      {Object.entries(projectDescription).map(([key, value]) => (
        value !== '' && (
          <div className='section-project-infos-item' key={key}>
            <div className='heading'>
              <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
            </div>
            <div className='content'>
              {key.toLowerCase() === 'website' ? (
                <a href={value} target="_blank" rel="noopener noreferrer">Visit site ↙︎</a>
              ) : (
                <p>{value}</p>
              )}
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default ProjectInfo;
