import React, { useEffect, useState } from 'react';
import '../assets/scss/scroll-indicator.scss';

const ScrollIndicator = () => {
    const [showScrollIndicator, setShowScrollIndicator] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            // Show the indicator only when at the top of the page
            setShowScrollIndicator(window.scrollY < 50);
        };

        // Listen for scroll events
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts or re-renders
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollDown = () => {
        window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };

    return (
        <div className="scroll-indicator-container">
            {showScrollIndicator && (
                <div className="scroll-indicator" onClick={handleScrollDown}>
                    Scroll ↓
                </div>
            )}
        </div>
    );
};

export default ScrollIndicator;

