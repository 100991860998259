import React from 'react';
import '../assets/scss/technical-sheet.scss';

const TechnicalSheet = ({ technicalSheet }) => {
  return (
    <div className='technicalSheet-container'>
      <h2>TECHNICAL SHEET</h2>
      <ul>
        {technicalSheet.map((tech, index) => (
          <li key={index}>{tech}</li>
        ))}
      </ul>
    </div>

  );
};


export default TechnicalSheet;
