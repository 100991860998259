import React from 'react';
import '../assets/scss/learning-experience.scss';

function LearningExperiences({ whatdidIlearn }) {
    return (
        <div className='learning-experiences-container'>
            <h2>WHAT I LEARNED</h2>
            {whatdidIlearn.map((learning, index) => (
                <div key={index} className="learning-item">
                    <h3 className='title-experience'>{learning.subjectLearning}</h3>
                    <p  className='description-experience' dangerouslySetInnerHTML={{ __html: learning.subjectLearningDescription }}></p>
                </div>
            ))}
        </div>
    );
}

export default LearningExperiences;