import React, { useEffect, useState } from 'react';
import projectsData from '../assets/data/projects.json';
import { Link } from 'react-router-dom';
import '../assets/scss/home.scss'; // Import your transition styles
import transition from '../transition';
import ScrollIndicator from '../components/ScrollIndicator';

const Home = () => {
  const [jsonData, setJsonData] = useState(null);
  const [filter, setFilter] = useState('All');
  const [selectedTag, setSelectedTag] = useState('All'); // State to track the selected tag

  useEffect(() => {
    // You can set jsonData directly if you don't need to fetch it asynchronously
    setJsonData(projectsData);
  }, []);

  // Assuming each project has a 'tags' array
  // We're also removing the duplicated tags
  const tags = ['All', ...new Set(projectsData.reduce((acc, project) => [...acc, ...project.tags], []))];

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setSelectedTag(newFilter); // Update the selected tag state
  };


  // Set the order of projects quickly
  // Now you can add any project data anywhere in the database and reoganize the order of the project here
  const slugsOrder = [
    "shop-lnacarla",
    "exclusive-lnacarla",
    "my-portfolio-v2",
    "ssk",
    "datananas",
    "lnacarla-v2",
    "lariviere",
    "lnacarla-v1",
    "my-portfolio-v1",
    "behind-the-kizomba",
  ]

  const reorderedProjects = slugsOrder.map(slug => projectsData.find(project => project.slug === slug));

  const filteredProjects = reorderedProjects?.filter(project =>
    filter === 'All' || project.tags.includes(filter)
  );

  const nbfilteredProjects = filteredProjects.length
  console.log('nbfilteredProjects =', nbfilteredProjects)

  if (!jsonData) {
    return <div>Loading...</div>;
  }

  return (
    <main className='home-container'>
      <ScrollIndicator></ScrollIndicator>
      <header className=''>
        <h2 className='home-subtitle'>Creative Developper Frontend</h2>
        <h1 className='home-title'>DAMIEN BIABIANY</h1>
        <div className='home-selected-works'>
          <h3>SELECTED WORKS</h3>
        </div>
        <div className="filters">
          {tags.map((tag) => (
            <button
              key={tag}
              className={`tag-button ${selectedTag === tag ? 'tag-button-selected' : ''}`} // Apply class conditionally
              onClick={() => handleFilterChange(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
        <div>
          Projects : {nbfilteredProjects}
        </div>
      </header>



      <div className='home-grid-projects-container'>
        {filteredProjects.map((project, index) => (
          <Link
            to={{
              pathname: `/project/${project.slug}`,
            }}
            key={index}
            className='project-item'
          >
            <h2 className='project-title'>
              {project.name.toUpperCase()}
            </h2>
            <div className="project-tags">
              {project.tags.map(tag => <span key={tag} onClick={() => handleFilterChange(tag)}>{tag} </span>)}
            </div>
            <div className='project-picture'>
              <img src={project.mainImage} alt={project.name} className="project-image" />
            </div>
            <p>{project.aboutTheProject}</p>
          </Link>
        ))}
      </div>
    </main>

  );
};

export default transition(Home);
