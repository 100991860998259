import React from 'react';
import transition from '../transition';
import '../assets/scss/about.scss';
import AboutData from '../assets/data/about.json';
import ScrollIndicator from '../components/ScrollIndicator';


const About = () => {
  return (
    <div className='About-container'>
      <ScrollIndicator></ScrollIndicator>
      <header className='About-section1'>
        <div className='About-title-container'>
          <div className='About-subtitle'>
            <h2>{AboutData.subtitle}</h2>
          </div>
        
          <div className='About-title'>
           {/*  <h2>{AboutData.firstName}</h2>
            <h2>{AboutData.lastName}</h2> */}
            <h2>{AboutData.cathphrase}</h2>
          </div> 
        </div>
        <div className='About-picture'>
          <img src={AboutData.profilPicture} alt="profile"></img>
        </div>
        <div />
      </header>
      {/* <h2>{AboutData.cathphrase}</h2> */}
      <section>
        <p>{AboutData.description1}</p>
      </section>
      <section>
        <p>{AboutData.description2}</p>
      </section>
      <section>
        <p>{AboutData.description3}</p>
      </section>
    </div>
  );
};

export default transition(About);
