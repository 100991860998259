import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/scss/lazy-load-image.scss';  // Ensure you have corresponding styles defined

const LazyLoadImage = ({ src, alt, ...props }) => {
    const imageRef = useRef(null);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const location = useLocation();
    const containerRef = useRef(null);

    useEffect(() => {
        // Trigger re-animation by resetting the animation
        if (containerRef.current) {
            containerRef.current.style.animation = 'none';
            setTimeout(() => {
                containerRef.current.style.animation = '';
            }, 10);  // Short timeout to reset the animation
        }
    }, [location]);  // Dependency array includes location to react to route changes

    useEffect(() => {
        const currentRef = imageRef.current;
        if (!currentRef) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { rootMargin: '100px' }
        );

        observer.observe(currentRef);

        return () => observer.unobserve(currentRef);
    }, [src]);  // Now reacts to changes in `src`

    return (
        <div className='LazyLoadImage--container' ref={containerRef} style={props.containerstyle}>
            <h2>{props.imagetitle.toUpperCase()}</h2>
            <img
                ref={imageRef}
                src={src}
                alt={alt}
                width={props.width}
                height={props.height}
                {...props}
            />
        </div>
    );
};

export default LazyLoadImage;
